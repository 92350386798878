import React from 'react';
import {Helmet} from 'react-helmet'

import GalleryComponent from "../components/gallery/gallery"
import Layout from "../components/layout.js"

const Gallery=() => {

    return (
        <Layout>
            <Helmet>
                <title>Wulkanizacja Tychy, Wymiana opon Tychy - Galeria</title>

                <link rel="canonical" href="https://oponytychy.pl/galeria" />
                <meta name="description" content="Galeria serwisu opon Tychy. Najnowocześniesze maszyny! Wymieniamy opony oraz wyważamy koła na światowej klasy maszynach! Sprzedajemy opony letnie, zimowe oraz wielosezonowe. Naprawiamy przebicia na bieżniku oraz przebicia boczne opon." />
                <meta name="keywords" content="wymiana opon, wulkanizacja Tychy, opony, naprawa opon" />
            </Helmet>

            <GalleryComponent />
        </Layout>
    )
}

export default Gallery
