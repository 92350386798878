import React from "react"
import Img from "gatsby-image"
import './gallery.scss';

import {withStyles, ButtonBase} from "@material-ui/core"

const style=theme => ({
    previewButton: {
        display: "inline-block",
        background: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        width: 260,
        borderRadius: 4,
        boxShadow: "0 0 25px -5px rgba(0,0,0,0.3)",
        [theme.breakpoints.up(480)]: {
            width: 320,
          },
        [theme.breakpoints.up(768)]: {
            width: 200,
          }
    }
})

const ThumbGrid=({images, handleOpen, classes}) => {
    const imagesOrdered=images.sort((imageA, imageB) => {
        const myRegex=/\/montazownica_+/;
        const strA=imageA.node.childImageSharp.fluid.src.split(myRegex)[1]
        const strB=imageB.node.childImageSharp.fluid.src.split(myRegex)[1]
        return strA.localeCompare(strB)
    })

    return imagesOrdered.map((image, i) => (
        <div key={i} className="thumbnailsGridItem">
            <ButtonBase onClick={handleOpen(i)} className={classes.previewButton}>
                <Img
                    fluid={image.node.childImageSharp.fluid}
                    className="thumbnailImg"
                />
            </ButtonBase>
        </div>
    ))
}

export default withStyles(style)(ThumbGrid)
