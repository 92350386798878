import React, {useState} from "react"
import {graphql, StaticQuery} from "gatsby"
import ThumbGrid from "./thumbnails"
import LightBox from "./lightbox"
import './gallery.scss'

const GalleryComponent = props => {
    const [showLightbox, setShowLightbox]=useState(false)
    const [selectedImage, setSelectedImage]=useState(null)

    const handleOpen=i => e => {
        setShowLightbox(true)
        setSelectedImage(i)
    }
    const handleClose=() => {
        setShowLightbox(false)
        setSelectedImage(null)
    }
    const handlePrevRequest=(i, length) => e => {
        setSelectedImage((i-1+length)%length)
    }
    const handleNextRequest=(i, length) => e => {
        setSelectedImage((i+1)%length)
    }
    return (
        <StaticQuery
            query={graphql`
        query allImgQuery {
          source: allFile(filter: { absolutePath: { regex: "/images/galleryPage/" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxHeight: 800) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      `}
            render={data => {
                const images=data.source.edges
                return (
                    <div className="main gallery">
                        <h2 className="p3-1 header"><span>Galeria: </span></h2>
                        <div className="thumbnailsGrid">
                            <ThumbGrid images={images} handleOpen={handleOpen} />
                            <div>
                                {showLightbox&&selectedImage!==null&&(
                                    <LightBox
                                        images={images}
                                        handleClose={handleClose}
                                        handleNextRequest={handleNextRequest}
                                        handlePrevRequest={handlePrevRequest}
                                        selectedImage={selectedImage}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )
            }}
        />
    )
}
export default GalleryComponent
